import React, { createContext, useState } from "react";

const defaultState = {
    firstRow: [],

    secondRow: [],

    thirdRow : [],

    testimonials : [],

    brides : [],

    shoot : [],

    showNav : false,

    showMenu : false,

    loading: true,

    locale: null,

    setLocal: null,

    showCovid: true,

    expandedCovid: false
}

export const FabContext = createContext(defaultState);

export const FabProvider = ({children}) => {
    const firstRow = [
        'alexam.jpg',
        'jeng.jpg',
        'coleenc.jpg',
        'courtneyb.jpg',
        'ashleyny.jpg'
    ]
    const secondRow = [
        'laurend.jpg',
        'talias.jpg',
        'shank.jpg',
        'heatherm.jpg',
        'ashleya.jpg',
    ]
    const thirdRow = [
        'nicolef.jpg',
        'cristend.jpg',
        'elizabeths.jpg',
        'alexap.jpg',
        'jasb.jpg',
    ]

    const testimonials = [
        {
            name: 'Jolie M.',
            id: 'joliem',
            url: 'joliem.jpg',
            text: `
            Farrah is an absolute PRO and truly felt like my wedding dress fairy godmother. She is so well connected in the industry and was able to get me appointments with my favorite designers at the snap of a finger. She made both me and my mom feel so comfortable throughout the entire process and was integral in making my wedding day perfect, despite the many challenges COVID threw our way. Cannot recommend FAB Styling enough.
            `
        },
        {
            name: 'Ellen S.',
            id: 'ellens',
            url: 'ellens.jpg',
            text: `
            I cannot say enough good things about my experience working with Farrah. Buying a wedding dress is one of the most anticipated moments in many women's lives - it certainly was for me. I had done a lot of looking online before I had arrived for my appointment, and had fallen in love with one particular dress. It had my heart, but was out of my price range and wasn't carried anywhere on the east coast, so I was already preparing myself to have to settle for a dress that wasn't quite what I wanted. <br/><br/>
            When I got to the fitting room, I met Farrah, and I could tell right away that she actually cared. She wanted to know about my wedding plans, my venue, my vision for how the day would look and how I wanted to feel.  <br/><br/>
            I showed her some options that I had selected from their inventory online, but I also showed her my beautiful dream dress. I let her know my budget too, because I was not one of those brides who could just decide to spend a little more if I really fell in love with a dress. <br/><br/>
            When she came back to the room, she was genuinely excited, and told me - "I found the dress! We crunched the numbers and it's in your budget!" She brought other options too, but I only needed to try on one dress. It was almost identical to the dress I had hoped for, and fit like a dream.  <br/><br/>
            Farrah's kindness, ability to listen and correctly understand my vision, and genuine care made this one of the most special memories I have from planning my wedding. I would recommend her a hundred times over, and can't thank her enough!
            `
        },
        {
            name: 'Madison D.',
            id: 'madisond',
            url: 'madisond.jpg',
            text: `
            For me, Farrah as my bridal consultant was an absolute must have! As a young bride with very little experience in the world of weddings, I needed lots of guidance on the industry.  At our first appointment Farrah went over all of the designers with me and explained to me their styles, backgrounds and price points.  It was very helpful to have someone who knew each designer so well (most of them personally!) that could help me narrow down to match my style.  The dress Farrah helped me pick out took everything into consideration, not just my personality, but also the personality of my wedding.  My favorite dress ended up being the first dress I tried on which Farrah spotted for me.  The dress originally came in cream but Farrah imagined it in white - the color I was hoping to wear.  When the dress came into the store all of the associates mentioned they had never seen it in white but it was their new favorite gown!  On your wedding day, and while planning a wedding, there is so much to worry about, and Farrah helped take all of the worry out of the dress shopping. I can’t imagine my wedding day in any other gown and without Farrah by my side!
            `
        },
        {
            name: 'Nastaran H.',
            id: 'nastaranh',
            url: 'nastaranh.jpg',
            text: `
            I wasn't the type of bride that had ever dreamed about my wedding day or the dress I would wear for the occasion and I felt overwhelmed by the myriad of options when I visited different boutiques. Working with Farrah was the highlight of my wedding dress shopping experience. She was patient, thoughtful, kind, and never made me feel bad about not knowing what I want or not liking a dress that was picked out for me. She made my experience much more enjoyable and my whole family felt very connected to her by the end of the session. To this day, we talk about how great she was and I highly recommend Farrah to all the newly engaged gals out there!
            `
        },
        {
            name: 'Elsbeth Z.',
            id:'elsbethz',
            url: 'elsbethz.jpg',
            text: `
            It is so hard to know where to begin in my praise of Farrah. <br/><br/>
            I first met her as a bride at the salon she was working with at the time. I could not have picked a better consultant for myself if I had been given everyone in the salon to choose from.  From the start we hit it off, and her first round of dresses contained the one that I ultimately ended up "Saying Yes" to (of course sobbing and smiling wider than I ever have). This shows me a few things: She is an excellent judge of the general vibe of the bride. She listens to requests and hopes for the perfect dress and chooses accordingly. And she is always considerate of budget. <br/><br/>
            I was so worried that having a small budget would limit my selection, or that she may say to me "Well we really don't have a big selection for that number but we will do our best," or something like that, but she never did. In fact at one point I was asking about price and she said "Don't worry, I won't show you anything you can't afford. I wouldn't do that to you." and yet the dresses KEPT COMING! There was no restriction, and no lack of selection; and my worries melted away. <br/><br/>
            We tried on so many dresses, some of which, were a real challenge, and some I couldn't try on at all. In those moments, I think it could be really easy for a bride to get down on herself, to feel like she has something wrong with her because the samples don't fit, but I never did. We laughed as we tried to accomplish the impossible, celebrated when we actually did, and worked together in a way you would expect of people who have known each other far longer than an hour. She is such an incredibly genuine human being, who loves what she does. She made me feel beautiful, supported, confident, and so safe.<br/><br/>
            When our appointment was over, she gave me her number and offered to help with “anything”, and boy did I take her up on that. I had no idea what I was doing with so many things, and in those moments of confusion, Farrah was there. I asked her about shoes, accessories and undergarments; but also about bridesmaids and all the other vendors I was struggling to find. One day she called me and proclaimed “I found you a photographer!”, and that is how I came to get my stunning photos from Jamie Levine. <br/><br/>
            
            My whole relationship with Farrah felt so central to my wedding experience, that I invited her to attend as a guest. This is where her true passion and excitement for her brides became crystal clear. She was so much more than a guest. She helped my bridesmaids and me get into our dresses, she fixed hair and tape, she built me up when I was feeling the panic of the day. After the ceremony, she fixed my dress for every photo, made sure my train was perfect in every shot. She bustled and unbustled me dozens of times to make sure I never had to worry about it. She became an old friend in the span of a day.<br/><br/>
            I can’t put into words how much all her care and attention to detail meant to me throughout the entire wedding process. Some brides have friends, mothers, or sisters to lean on for these moments; but I had Farrah. My day would not have been what it was without her. I am forever grateful.<br/><br/>
            `
        },
        {
            name: 'Stephanie B.',
            id: 'stephanieb',
            url: 'stephanieb.jpg',
            text: `
            I met Farrah in February 2019 for my first (and only!) dress shopping appointment, after receiving many recommendations for her as a consultant in a brides-based Facebook group I was a part of.  The recommendations did NOT disappoint!  The moment I met Farrah, I felt like I had known her for years.  She took the time to get to know me and my fiancé’s story before diving into the dress aspect of my appointment.  She made me feel so comfortable and truly listened to my style requests and my budget.  She found me the perfect dress in only five, yes FIVE dresses.  And cried with me when I realized it was the one.  I cannot imagine having shopped with any other consultant, especially not after the experience I had with Farrah.  She’s exceptionally personable and made my family (all EIGHT that I brought with me) feel just as special as me. Highly highly HIGHLY recommend working with this woman!!!
            `
        },
        {
            name: 'Ashley Y.',
            id:'ashleyny',
            url: 'ashleyny.jpg',
            text: `
            Working with Farrah was an absolute dream. I traveled from Detroit to New York to find my dream dress. After only spending a few short minutes with me, she immediately understood what I was looking for and pulled three gorgeous Pnina Tornai gowns. The 3rd dress was the dress I ended up choosing (Yes, I cried like a baby!). What was ironic is I traveled to New York dead set on another designer's gown. Farrah had knowledge of this designer as well and advised she wasn't thrilled with their quality. I ended up going to the two other appointments I had that day, and nothing came close to that gorgeous Pnina gown she pulled for me. And she was right, the designer I had flown in for was a major disappointment. I went back to see her at Kleinfeld's that evening to say "Yes to My Dream Dress!". Oh, and who you ask asked me that wonderful question? Why Queen Pnina Tornai herself! When Farrah realized I was on my way back, she asked Pnina to stay and meet me. Can you say pinch me?! A total dream come true. She made the process so incredibly special and I'm so grateful to her. She also helped me find the perfect veil and accessories! Veil by Pnina and Crown & Earrings by Maria Elena. 
            `
        },
        {
            name: 'Lauren G.',
            id: 'laureng',
            url: 'laureng.jpg',
            text: `
            It was a delight working with Farrah. Her bridal expertise and eye for fashion helped me to select the perfect dress for my wedding. She was patient, but quick to process and pull exactly what I was looking for style-wise. Farrah even connected me and the designer to further customize my dress to suit my vision and needs. I would definitely recommend working with her!!
            `
        },
        {
            name: 'Jaimee K.',
            id: 'jaimeek',
            url: 'jaimeek.jpg',
            text: `
            The emotion you feel walking into a salon to pick out your dream dress is unexplainable. You feel like you’re living in a dream. Then, when you see the amount of dress options it gets overwhelming. I was lucky enough to be paired with Farrah as my stylist. She listened to everything I said I wanted and after only a few dresses I found the dress of my dreams. She was easy to talk to and knew so much about dress styles etc. Still two years later people talk about that dress and how timeless and elegant it was. I am so lucky I found Farrah!
            `
        },
    ]

    const brides = [
       { url:'alexap.jpg', jamie: false},
       { url:'alexam.jpg', jamie: false},
       { url:'monicag.jpg', jamie: true},
       { url:'laurend.jpg', jamie: false},
       { url:'jasb.jpg', jamie: false},
       { url:'lesliew.jpg', jamie: false},
       { url:'shank.jpg', jamie: false},
       { url:'nicoles.jpg', jamie: false},
       { url:'ashleya.jpg', jamie: true},
       { url:'cristend.jpg', jamie: true},
       { url:'jens.jpg', jamie: true},
       { url:'talias.jpg', jamie: false},
       { url:'emilyw.jpg', jamie: true},
       { url:'joliem.jpg', jamie: false},
       { url:'heatherm.jpg', jamie: true},
       { url:'esteec.jpg', jamie: false},
       { url:'alexap2.jpg', jamie: false},
       { url:'alexam2.jpg', jamie: false},
       { url:'ellens.jpg', jamie: false},
       { url:'elsbethz.jpg', jamie: true},
       { url:'heatherm2.jpg', jamie: true},
       { url:'madisond.jpg', jamie: false},
       { url:'nicoles2.jpg', jamie: false},
       { url:'nicolef2.jpg', jamie: false},
       { url:'shank2.jpg', jamie: false},
       { url:'stephanieb.jpg', jamie: false},
       { url:'alexam3.jpg', jamie: false},
       { url:'coleenc.jpg', jamie: true},
       { url:'courtneyb.jpg', jamie: true},
       { url:'ellens2.jpg', jamie: false},
       { url:'emilyw2.jpg', jamie: true},
       { url:'jeng.jpg', jamie: true},
       { url:'laurend2.jpg', jamie: false},
       { url:'monicag2.jpg', jamie: true},
       { url:'talias2.jpg', jamie: false},
       { url:'esteec2.jpg', jamie: false},
       { url:'jasb2.jpg', jamie: false},
    ]

    const shoot =['01.jpg','02.jpg','03.jpg','04.jpg','05.jpg','06.jpg','07.jpg','08.jpg','09.jpg','10.jpg','11.jpg','12.jpg','13.jpg','14.jpg','15.jpg','16.jpg','17.jpg','18.jpg','19.jpg','20.jpg',]

    const [showNav, setShowNav] = useState(false);

    const [showMenu, setShowMenu] = useState(false)

    const [loading, setLoading] = useState(true)

    const [locale,setLocal]= useState(null);

    const [showCovid, setCovid] = useState(true);

    const [expandedCovid, setExpand] = useState(false);
  
  return ( 
        <FabContext.Provider 
                value={{
                    firstRow,
                    secondRow,
                    thirdRow,
                    testimonials,
                    showMenu,
                    setShowMenu,
                    locale,
                    setLocal,
                    brides,
                    showNav,
                    setShowNav,
                    shoot,
                    showCovid,
                    setCovid,
                    expandedCovid,
                    setExpand,
                    loading,
                    setLoading
                }}>
         {children}
        </FabContext.Provider> );
};