// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-about-js": () => import("./../../../src/templates/About/About.js" /* webpackChunkName: "component---src-templates-about-about-js" */),
  "component---src-templates-brides-brides-js": () => import("./../../../src/templates/Brides/Brides.js" /* webpackChunkName: "component---src-templates-brides-brides-js" */),
  "component---src-templates-experiences-experiences-js": () => import("./../../../src/templates/Experiences/Experiences.js" /* webpackChunkName: "component---src-templates-experiences-experiences-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/Home/Home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-not-found-not-found-js": () => import("./../../../src/templates/NotFound/NotFound.js" /* webpackChunkName: "component---src-templates-not-found-not-found-js" */),
  "component---src-templates-testimonials-expanded-js": () => import("./../../../src/templates/Testimonials/Expanded.js" /* webpackChunkName: "component---src-templates-testimonials-expanded-js" */),
  "component---src-templates-testimonials-testimonials-js": () => import("./../../../src/templates/Testimonials/Testimonials.js" /* webpackChunkName: "component---src-templates-testimonials-testimonials-js" */)
}

