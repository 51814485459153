import React from 'react';
import { FabProvider } from "./src/context/FabContext";
import { Helmet } from "react-helmet";


export const wrapRootElement = ({ element }) => {

  return(
    <FabProvider>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Farrah Arenson Bridal Styling</title>
          <link rel="canonical" href="" />
          <meta name="description" content="Having a partner to guide you through the wedding dress shopping process can eliminate the stress, and elevate the excitement, and I am here to help you find your Bridal Style.   Whether it’s finding your gown, or getting you ready on your wedding day, I am here to take care of it all!" />
          <meta name="keywords" content="stylist,attire,wedding,bride,farrah,arenson,fab,new york, nyc,groom, the knot, wedding wire, plus size, fat, black, woman, engaged, jewish, fiancé, fiance, jackie, avrumson, stylish, bride,bridal, plus, size, dress, vestido, kleinfeld, david bridal, david's bridal, wedding dresses, dress for wedding, bridal stylist, dresses wedding, vera wang, bridesmaid dress, bridesmaid, white dress, formal dress" />
          <meta property="og:title" content="Farrah Arenson Bridal Styling"/>
          <meta property="og:description" content="Having a partner to guide you through the wedding dress shopping process can eliminate the stress, and elevate the excitement, and I am here to help you find your Bridal Style.   Whether it’s finding your gown, or getting you ready on your wedding day, I am here to take care of it all!"/>
          <meta property="og:image" content="http://fabridalstyling.com/images/social.jpg" />
      </Helmet>
      {element}
    </FabProvider>
  )
  }